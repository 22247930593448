import React, { useEffect } from 'react'
import { BasicInfo, Description, DonationList, Forwardings, ManageContent, News, Numbers, Sharing, Sticky } from 'fepp'
import { ContentHeader } from '../content_header'
import { FundraisingEventApiResult } from './types'
import { PageProps } from './types'
import { useJsonApi } from 'shared'

const useScrollToAnchor = (elementIds: string[], shouldScroll: boolean) => {
  useEffect(() => {
    if (!shouldScroll || !window.location.hash) return
    if (!elementIds.includes(window.location.hash.replace('#', ''))) return
    const observer = new MutationObserver((_, obs) => {
      const ele = document.querySelector(window.location.hash)
      if (!ele) return
      ele.scrollIntoView()
      obs.disconnect()
    })

    observer.observe(document, { childList: true, subtree: true })
    return () => {
      observer.disconnect()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldScroll])
}

export const Page = (props: PageProps): JSX.Element | null => {
  const { data: fundraisingEvent } = useJsonApi<FundraisingEventApiResult>(props.apiUrl)

  useScrollToAnchor(['description', 'share', 'forwardings', 'news'], !!fundraisingEvent)

  if (!fundraisingEvent) return null

  // TODO Move hardcoded values to an injectable config; old comment -- Used for CrowdFunDay
  const separateCompanyAndUserDonations = fundraisingEvent.id === 36304

  return (
    <div>
      <ManageContent fundraisingEvent={fundraisingEvent} {...props} />
      <ContentHeader
        fundraisingEvent={fundraisingEvent}
        videoUrl={props.videoUrl}
        sponsoringPicture={props.sponsoringPicture}
        viewUserUrl={props.viewUserUrl}
        backgroundColor={props.backgroundColor}
        showManageContent={props.showManageContent}
      />
      <div className="fundraising-events-show fundraising-events-controller">
        <div className="content-wrapper">
          <Sticky fundraisingEvent={fundraisingEvent} />
          <BasicInfo
            fundraisingEvent={fundraisingEvent}
            sponsoringName={props.sponsoringName}
            videoUrl={props.videoUrl}
          />
          <Numbers
            fundraisingEvent={fundraisingEvent}
            donatedAmountCaption={props.donatedAmountCaption}
            sponsoringAmountCents={props.sponsoringAmountCents}
            sponsoringAmountCaption={props.sponsoringAmountCaption}
          />
          {separateCompanyAndUserDonations ? (
            <>
              <DonationList fundraisingEvent={fundraisingEvent} filter={{ company_donation: false }} />
              <DonationList
                fundraisingEvent={fundraisingEvent}
                headline="Firmenspenden"
                filter={{ company_donation: true }}
                perPage={4}
              />
            </>
          ) : (
            <DonationList fundraisingEvent={fundraisingEvent} />
          )}
          <Description fundraisingEvent={fundraisingEvent} />
          <News fundraisingEvent={fundraisingEvent} />
          <Forwardings fundraisingEvent={fundraisingEvent} />
          <Sharing fundraisingEvent={fundraisingEvent} />
        </div>
      </div>
    </div>
  )
}

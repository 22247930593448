import React from 'react'

import { DonateButton } from 'fepp'
import { DonateButtonComboProps } from './types'
import { TaxNotice } from 'shared'

export const DonateButtonCombo = ({
  fundraisingEvent,
  buttonLocation,
  labelClosed,
}: DonateButtonComboProps): JSX.Element => {
  return (
    <div style={{ maxWidth: '300px', margin: '0 auto' }}>
      {fundraisingEvent.donations_prohibited || (
        <>
          <DonateButton
            className="btn-huge btn-block mb-1"
            fundraisingEvent={fundraisingEvent}
            buttonLocation={buttonLocation}
          />
          <TaxNotice className="generic-tax-deductibility-hint" />
        </>
      )}
      {labelClosed && fundraisingEvent.closed_at && (
        <span className="badge badge-closed">{i18n.t('misc.state_labels.closed')}</span>
      )}
    </div>
  )
}

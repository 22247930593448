import React, { useCallback, useEffect, useState } from 'react'
import useWindowScroll from 'react-use/lib/useWindowScroll' // TODO: revert import change when this issue is resolved: https://github.com/streamich/react-use/issues/2074
import useWindowSize from 'react-use/lib/useWindowSize' // TODO: revert import change when this issue is resolved: https://github.com/streamich/react-use/issues/2074
import { DonateButton } from 'fepp'
import { ShareButtons, findApiV4Link } from 'shared'
import { StickyProps } from './types'

export const Sticky = ({ fundraisingEvent }: StickyProps): JSX.Element | null => {
  const [visible, setVisible] = useState(false)
  const [left, setLeft] = useState(0)

  const handleScroll = useCallback(() => {
    const triggerDistance = document.querySelector('.fundraising-events-show-numbers')?.getBoundingClientRect().top ?? 0
    const unTriggerDistance =
      (
        document.querySelector('.fundraising-events-show-sharing') || document.querySelector('footer')
      )?.getBoundingClientRect().top ?? 0
    setVisible(triggerDistance <= 0 && unTriggerDistance >= 0)
  }, [])

  const handleResize = useCallback(() => {
    let bodyLeft = document.querySelector('.fixed-viewport-width')?.getBoundingClientRect().left ?? 0
    if (bodyLeft < 0) bodyLeft = 0
    setLeft(bodyLeft)
  }, [])

  const { y } = useWindowScroll()
  useEffect(() => {
    y == 0 || handleScroll()
  }, [y, handleScroll])
  const { width, height } = useWindowSize()
  useEffect(() => {
    width && handleResize()
  }, [width, height, handleResize])

  if (fundraisingEvent.donations_prohibited) return null

  const shareUrl = findApiV4Link({ ofObject: fundraisingEvent, withRel: 'platform' }) ?? ''

  return (
    <div id="sticky-bar" style={{ opacity: visible ? 1 : 0, left: left }}>
      <div className="sticky-wrapper justify-between items-center flex">
        <div className="hidden desktop:block">
          <strong>{fundraisingEvent.title}</strong>
        </div>
        <div className="flex fepp-actions">
          <div className="flex">
            <div className="flex items-center">
              <ShareButtons
                fundraisingEvent
                platforms={['facebook', 'whatsapp', 'link']}
                mobileOnly={['whatsapp']}
                shape="square"
                shareUrl={shareUrl}
                utmMedium="fepp_sticky"
              />
            </div>
          </div>
          <div className="ml-4">
            <DonateButton fundraisingEvent={fundraisingEvent} className="btn-large" buttonLocation="FunE Sticky" />
          </div>
        </div>
      </div>
    </div>
  )
}

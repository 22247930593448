import React from 'react'
import { DonateButtonCombo } from 'fepp'
import { ShareButtons, findApiV4Link } from 'shared'
import { SharingProps } from './types'

export const Sharing = ({ fundraisingEvent }: SharingProps): JSX.Element => {
  const shareUrl = findApiV4Link({ ofObject: fundraisingEvent, withRel: 'platform' }) ?? ''

  return (
    <div className="fundraising-events-show-sharing" id="share">
      <div className="container">
        <div className="row">
          <div className="col-md-24 text-center">
            <h2 className="text-3xl mt-0 mb-8 text-center">{i18n.t('fundraising_events.show.sharing.headline')}</h2>

            <DonateButtonCombo fundraisingEvent={fundraisingEvent} buttonLocation="FunE Share" />

            <h3 className="text-2xl mt-10 mb-8 text-center">
              {i18n.t('fundraising_events.show.sharing.share_call_to_action')}
            </h3>

            <div className="row">
              <div className="col-md-6 offset-md-9">
                <ShareButtons
                  fundraisingEvent
                  platforms={['facebook', 'twitter', 'link', 'whatsapp', 'messenger', 'instagram']}
                  mobileOnly={['whatsapp', 'messenger']}
                  shape="round"
                  shareUrl={shareUrl}
                  utmMedium="fepp_sharing_module"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

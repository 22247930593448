import React from 'react'
import analytics, { extractIdFromSlug } from '@betterplace/product-analytics'
import classnames from 'classnames'
import { DonateButtonProps } from './types'
import { findApiV4Link } from 'shared'

export const DonateButton = ({
  fundraisingEvent,
  className,
  buttonLocation,
}: DonateButtonProps): JSX.Element | null => {
  if (fundraisingEvent.donations_prohibited) return null
  const donateUrl = findApiV4Link({ ofObject: fundraisingEvent, withRel: 'new_donation' })
  return (
    <a
      className={classnames('btn btn-primary', className)}
      href={donateUrl}
      onClick={() => {
        analytics.donationButtonClicked({
          id: extractIdFromSlug(fundraisingEvent.id),
          receiver_type: 'FundraisingEvent',
          button_location: buttonLocation,
        })
      }}
    >
      {i18n.t('misc.donate_button')}
    </a>
  )
}

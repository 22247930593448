import React, { useCallback, useMemo } from 'react'
import { ForwardableAmountProps, Forwarding, ForwardingApiResult, ForwardingProps, SumProps } from './types'
import { I18nHtml, PlainText, findApiV4Link, useJsonApi } from 'shared'
import { formatCents } from '@betterplace/utils'

export const Forwardings = ({ fundraisingEvent }: ForwardingProps): JSX.Element => {
  const apiURL = findApiV4Link({ ofObject: fundraisingEvent, withRel: 'forwardings' }) ?? ''
  const { data } = useJsonApi<ForwardingApiResult>(apiURL)
  const fws = useMemo(() => (data && data.data) || [], [data])
  const sumForwardedCents = useMemo(() => fws.reduce((acc, curr) => acc + curr.forwarded_amount_in_cents, 0), [fws])
  const forwardingLines = useMemo(() => fws.map((forwarding, i) => <ForwardingCmp {...forwarding} key={i} />), [fws])
  const onShareClicked: React.MouseEventHandler<HTMLAnchorElement> = useCallback((event) => {
    event.preventDefault()
    document.querySelector('#share')?.scrollIntoView({ behavior: 'smooth' })
  }, [])
  return (
    <div className="fundraising-events-show-forwardings has-generic-scroll-down-button mx-auto" id="forwardings">
      <div style={{ minHeight: 130 }}>
        <div className="container">
          <div className="row fundraising-events-forwardings-index">
            <div className="col-md-16 offset-md-4 text-center">
              <h2 className="text-3xl mt-0 mb-8 text-center">
                {i18n.t('fundraising_events.forwardings.index.headline')}
              </h2>
              <h3 className="text-2xl mt-0 mb-8 text-center">
                {i18n.t('fundraising_events.forwardings.index.introduction_html')}
              </h3>
            </div>
            <div className="col-md-14 offset-md-5">
              <table className="bpc-table generic-calculation-table fundraising-events-forwarding-summary-table">
                <tbody>
                  {forwardingLines}
                  <ForwardableAmount
                    contactName={fundraisingEvent.contact?.name}
                    cents={fundraisingEvent.donated_amount_in_cents - sumForwardedCents}
                  />
                  <Sum cents={fundraisingEvent.donated_amount_in_cents} />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <a className="generic-scroll-down-button" onClick={onShareClicked} href="#share">
        <span className="sr-only">{i18n.t('fundraising_events.show.scroll_down_button')}</span>
      </a>
    </div>
  )
}

const ForwardingCmp = (props: Forwarding): JSX.Element => {
  const projectUrl = findApiV4Link({ ofObject: props, withRel: 'platform' })
  /* comes as "sanitized" HTML from APIv4 */
  const title = projectUrl ? (
    <a href={projectUrl}>
      <PlainText>{props.title}</PlainText>
    </a>
  ) : (
    <PlainText as="strong">{props.title}</PlainText>
  )
  const formattedAmount = formatCents(i18n.locale, props.forwarded_amount_in_cents)
  let amount = <strong>{formattedAmount}</strong>
  if (props.forwarded_amount_in_cents === 0) {
    amount = (
      <span>
        {i18n.t('fundraising_events.forwardings.index.zero_prefix_label')} {formattedAmount}
      </span>
    )
  }

  return (
    <tr>
      <td>{title}</td>
      <td className="amount">{amount}</td>
    </tr>
  )
}

const ForwardableAmount = ({ cents, contactName }: ForwardableAmountProps): JSX.Element | null => {
  if (!cents) return null
  return (
    <tr>
      <I18nHtml
        as="td"
        i18nKey="fundraising_events.forwardings.index.not_forwarded_label"
        options={{ manager_name: contactName }}
      />
      <td className="amount">
        <strong>{formatCents(i18n.locale, cents)}</strong>
      </td>
    </tr>
  )
}

const Sum = ({ cents }: SumProps): JSX.Element => {
  return (
    <tr className="sum">
      <td>
        <strong>{i18n.t('fundraising_events.forwardings.index.sum_of_donations_label')}</strong>
      </td>
      <td className="amount">
        <strong>{formatCents(i18n.locale, cents)}</strong>
      </td>
    </tr>
  )
}

import React from 'react'

import classNames from 'classnames'
import { BasicInfoProps } from './types'
import { DonateButtonCombo } from 'fepp'
import { PlainText, SharingGridButton, findApiV4Link } from 'shared'

export const BasicInfo = ({ fundraisingEvent, sponsoringName }: BasicInfoProps): JSX.Element => {
  const shareUrl = findApiV4Link({ ofObject: fundraisingEvent, withRel: 'platform' }) ?? ''

  return (
    <div className="fundraising-events-show-basic-information container">
      <div className="row text-center">
        <div className="col-md-20 offset-md-2">
          <PlainText
            as="div"
            className={classNames('manager-name', { 'mb-6': fundraisingEvent.profile_picture?.fallback })}
          >
            {i18n.t('fundraising_events.show.basic_information.subheadline', {
              name: sponsoringName || fundraisingEvent.contact?.name,
            })}
          </PlainText>
          <PlainText className="text-xl desktop:text-4xl mb-6" as="h1">
            {fundraisingEvent.title}
          </PlainText>

          <ActivateDonationsTimer time={fundraisingEvent.activate_donations_at} />

          <div className="mb-4 mx-auto" style={{ maxWidth: '300px' }}>
            <SharingGridButton
              shareUrl={shareUrl}
              platforms={['facebook', 'twitter', 'link', 'whatsapp', 'messenger', 'instagram']}
              modalHeader={i18n.t('fundraising_events.show.basic_information.share_modal_headline')}
              buttonLabel={i18n.t('fundraising_events.show.basic_information.share_button_label')}
              size="huge"
              block
              utmMedium="fepp_stats"
            />
          </div>
          <DonateButtonCombo fundraisingEvent={fundraisingEvent} buttonLocation="FunE Main" labelClosed />
        </div>
      </div>
    </div>
  )
}

const ActivateDonationsTimer = ({ time: time_ }: { time: NullableString }): JSX.Element | null => {
  if (!time_) return null
  const time = Date.parse(time_)
  if (time < Date.now()) return null

  return (
    <div className="text-xl desktop:text-3xl font-bold text-teal-700 mb-6">
      {i18n.t('fundraising_events.show.basic_information.activate_donation_timer_info', {
        date: i18n.l('date.formats.default2', time),
        time: i18n.l('date.formats.time', time),
      })}
    </div>
  )
}

import React from 'react'
import { DescriptionProps, Project, ProjectsApiResult } from './types'
import { I18nHtml, PlainText, StateLabel, TrustedHtml, findApiV4Link, useJsonApi } from 'shared'

export const Description = ({ fundraisingEvent }: DescriptionProps) => {
  const apiURL = findApiV4Link({ ofObject: fundraisingEvent, withRel: 'featured_projects' }) ?? ''
  const { data } = useJsonApi<ProjectsApiResult>(apiURL)

  const projects = (data && data.data) || []
  const count = (data && data.total_entries) || 0

  const contact = fundraisingEvent.contact
  const contactPicture = contact?.picture && findApiV4Link({ ofObject: contact.picture, withRel: 'fill_100x100' })
  const newMessageUrl = findApiV4Link({ ofObject: fundraisingEvent, withRel: 'new_message' })

  return (
    <div
      className="fundraising-events-show-description has-generic-scroll-down-button"
      id="description"
      data-testid="description"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-24">
            <h2 className="text-3xl mt-0 mb-8 text-center">{i18n.t('fundraising_events.show.description.headline')}</h2>
          </div>
          <div className="col-md-16 left-column pr-4 text-lg">
            <TrustedHtml as="div" className="trix-content">
              {fundraisingEvent.description}
            </TrustedHtml>

            <div className="creation-information">
              <img className="manager-image" src={contactPicture} width="100" height="100" alt="" />
              <p className="mb-0">
                <PlainText as="strong">{contact?.name}</PlainText>{' '}
                <span>
                  {i18n.t('fundraising_events.show.description.creation_information_html', {
                    created_at: i18n.l('date.formats.default', fundraisingEvent.created_at),
                  })}
                </span>{' '}
                <a href={newMessageUrl}>{i18n.t('fundraising_events.show.description.new_message_link')}</a>
              </p>
            </div>
          </div>

          <div className="col-md-8 right-column has-reloaded-content" style={{ minHeight: 200 }}>
            <div className="fundraising-events-projects-index">
              <h4 className="mb-4">{i18n.t('fundraising_events.projects.index.headline', { count })}</h4>
              <div className="project-list">
                {projects.map((project, i) => (
                  <ProjectCmp key={i} {...project} />
                ))}
              </div>
              <I18nHtml
                as="p"
                style={{ fontSize: '14px' }}
                i18nKey="fundraising_events.show.description.hint_html"
                options={{ name: contact?.name }}
              />
            </div>
          </div>
        </div>
      </div>
      <a
        className="generic-scroll-down-button"
        onClick={(event) => {
          event.preventDefault()
          document.querySelector('#news')?.scrollIntoView({ behavior: 'smooth' })
        }}
        href="#news"
      >
        <span className="sr-only">{i18n.t('fundraising_events.show.scroll_down_button')}</span>
      </a>
    </div>
  )
}

/**
 * @TODO Figure out if the projectStatus function from useProject cannot be re-utilized
 */
const projectStatus = (project: Project) => {
  if (project.completed_at) return 'completed'
  if (project.closed_at) return 'closed'
  return
}

const ProjectCmp = (project: Project): JSX.Element => {
  const url = findApiV4Link({ ofObject: project, withRel: 'platform' })
  const image = findApiV4Link({
    ofObject: project.carrier?.picture ?? { links: [] },
    withRel: 'fill_100x100',
  })
  const status = projectStatus(project)

  return (
    <a href={url}>
      <div className="media mb-3">
        <div className="media-body">
          <p className="media-heading mb-0">
            <StateLabel state={status} />{' '}
            <PlainText as="span" className="highlight-on-hover">
              {project.title}
            </PlainText>
          </p>
        </div>
        <div className="media-right">
          <img className="media-object" src={image} width="70" height="70" alt="" />
        </div>
      </div>
    </a>
  )
}

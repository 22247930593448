import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { ContentHeaderProps, LargeTitleProps, MediaProps, ProfilePictureProps, VideoProps } from './types'
import { PlainText, findApiV4Link } from 'shared'

export const ContentHeader = ({
  fundraisingEvent,
  sponsoringPicture,
  videoUrl,
  viewUserUrl,
  backgroundColor,
  showManageContent,
}: ContentHeaderProps): JSX.Element => {
  const [videoStarted, setVideoStarted] = useState(false)
  const profilePicture =
    (fundraisingEvent.profile_picture &&
      findApiV4Link({ ofObject: fundraisingEvent.profile_picture, withRel: 'fill_730x380' })) ||
    undefined
  const contactPicture =
    fundraisingEvent.contact?.picture &&
    findApiV4Link({
      ofObject: fundraisingEvent.contact.picture,
      withRel: 'fill_100x100',
    })
  const headerPicture = findApiV4Link({ ofObject: fundraisingEvent, withRel: 'header_picture' })
  const hasMedia = videoUrl || profilePicture
  const headerPictureStyleAttrs =
    (headerPicture && {
      backgroundImage: `url(${headerPicture})`,
      backgroundRepeat: 'no-repeat',
    }) ||
    undefined
  const backgroundStyleAttrs = !hasMedia
    ? undefined
    : (backgroundColor && {
        backgroundColor: `#${backgroundColor}`,
      }) || {
        backgroundColor: `#ffffff`,
      }

  return (
    <div
      style={{ ...headerPictureStyleAttrs, ...backgroundStyleAttrs }}
      className={`generic-content-header desktop:pt-205 bg-teal-500 fundraising-events-content-header ${
        hasMedia ? 'has-media' : 'has-no-media'
      } ${headerPicture ? 'has-custom-bg' : ''}`}
    >
      <div
        className={classNames('container', showManageContent === 'false' && 'preserves-space-for-transparent-header')}
      >
        <div className="flex justify-center -mx-205 desktop:mx-0">
          {hasMedia ? (
            <Media videoUrl={videoUrl} setVideoStarted={setVideoStarted} profilePicture={profilePicture} />
          ) : (
            <LargeTitle fundraisingEvent={fundraisingEvent} />
          )}
        </div>
        <a
          href={viewUserUrl}
          className={`fundraising-event-manager-picture-container ${videoStarted ? 'moved-to-background' : ''}`}
        >
          <img
            className={`fundraising-event-manager-picture ${sponsoringPicture ? 'has-sponsoring' : ''}`}
            src={sponsoringPicture || contactPicture}
            alt=""
          />
        </a>
      </div>
    </div>
  )
}

const Media = ({ videoUrl, setVideoStarted, profilePicture }: MediaProps): JSX.Element | null => {
  if (videoUrl) return <Video videoUrl={videoUrl} setVideoStarted={setVideoStarted} />
  if (profilePicture) return <ProfilePicture src={profilePicture} />
  return null
}

const Video = ({ videoUrl, setVideoStarted }: VideoProps): JSX.Element => {
  useEffect(() => initYoutubePlayer({ videoUrl, setVideoStarted }), [videoUrl, setVideoStarted])

  return (
    <div className="fundraising-event-video">
      <iframe
        title="video"
        allowFullScreen
        width="508"
        height="286"
        id="fundraising-event-video-player"
        src={videoUrl}
        frameBorder="0"
      />
    </div>
  )
}

const initYoutubePlayer = ({ videoUrl, setVideoStarted }: VideoProps): void => {
  if (!videoUrl) return

  window.onYouTubeIframeAPIReady = () => {
    new YT.Player('fundraising-event-video-player', {
      events: {
        onStateChange: (event: YT.OnStateChangeEvent) => {
          switch (event.data) {
            case YT.PlayerState.PLAYING:
              setVideoStarted(true)
              break
            case YT.PlayerState.ENDED:
              setVideoStarted(false)
              break
          }
        },
      },
    })
  }

  const script = document.createElement('script')
  script.src = 'https://www.youtube.com/iframe_api'
  const firstScriptTag = document.getElementsByTagName('script')[0]
  firstScriptTag?.parentNode?.insertBefore(script, firstScriptTag)
}

const ProfilePicture = ({ src }: ProfilePictureProps): JSX.Element => (
  <img className="fundraising-event-profile-picture" src={src} alt="" />
)

const LargeTitle = ({ fundraisingEvent }: LargeTitleProps): JSX.Element => (
  <div className="col-md-24">
    <div className="pb-5 desktop:pb-0 text-center">
      <PlainText as="h1" className="text-4xl mt-6 mb-12 desktop:mb-24">
        {fundraisingEvent.title}
      </PlainText>
    </div>
  </div>
)

import React, { useCallback, useState } from 'react'
import { BlogPostApiResult, ExpandedProps, NewsProps } from './types'
import { Controls, I18nHtml, PlainText, SanitizationPresets, TrustedHtml, findApiV4Link, useJsonApi } from 'shared'

export const News = ({ fundraisingEvent }: NewsProps): JSX.Element | null => {
  const [page, setPage] = useState(1)
  const apiUrl =
    (findApiV4Link({ ofObject: fundraisingEvent, withRel: 'blog_posts' }) ?? '') +
    `?per_page=1&page=${page}&order=published_at:desc`
  const { data } = useJsonApi<BlogPostApiResult>(apiUrl)
  const onForwardingsClicked: React.MouseEventHandler<HTMLAnchorElement> = useCallback((event) => {
    event.preventDefault()
    document.querySelector('#forwardings')?.scrollIntoView({ behavior: 'smooth' })
  }, [])
  const onLoadNextPage = useCallback(() => {
    setPage((current) => {
      const next = current + 1
      return next
    })
  }, [])
  const onLoadPreviousPage = useCallback(() => {
    setPage((current) => {
      const next = current - 1
      return next
    })
  }, [])
  if (!data) return null
  const blog = data.data[0]

  return (
    <div className="fundraising-events-show-news has-generic-scroll-down-button" id="news">
      <div className="container">
        <div style={{ minHeight: 130 }} className="has-reloaded-content">
          <div className="row fundraising-events-news-recent">
            <div className="col-md-24 text-center">
              <div className="headline-container">
                <h2 className="text-3xl mt-0 mb-8">{i18n.t('fundraising_events.news.recent.headline')}</h2>

                <Controls
                  total={data.total_pages}
                  current={data.current_page}
                  loadNextPage={onLoadNextPage}
                  loadPreviousPage={onLoadPreviousPage}
                />
              </div>
            </div>
            <div className="news-recent news-controller col-md-24">
              {!blog ? <NoResult /> : <Expanded blog={blog} />}
            </div>
          </div>
        </div>

        <a className="generic-scroll-down-button" onClick={onForwardingsClicked} href="#forwardings">
          <span className="sr-only">{i18n.t('fundraising_events.show.scroll_down_button')}</span>
        </a>
      </div>
    </div>
  )
}

const NoResult = () => (
  <div className="generic-no-results text-center">
    <p>
      <strong>{i18n.t('news.no_results.notice')}</strong>
    </p>
  </div>
)

const Expanded = ({ blog }: ExpandedProps): JSX.Element => {
  const url = findApiV4Link({ ofObject: blog, withRel: 'platform' })
  const author = blog.author ? blog.author.name : ''
  const authorImage =
    blog.author && blog.author.picture ? findApiV4Link({ ofObject: blog.author.picture, withRel: 'fill_100x100' }) : ''

  return (
    <div className="news-expanded-blog-container pb-6">
      <div className="row">
        <div className="col-md-18 offset-md-3">
          <div className="news-linked-blog">
            <a href={url}>
              <PlainText as="h1" className="mt-0 mb-4">
                {blog.title}
              </PlainText>
            </a>
            <div className="news-blog-body">
              <div className="flex items-center mt-0 mb-6">
                <img className="user-avatar rounded-full" src={authorImage} width="50" height="50" alt="" />
                {/* comes as "sanitized" HTML from APIv4, can use i18n_html instead after we clean it up */}
                <I18nHtml
                  as="div"
                  className="ml-2"
                  i18nKey="news.news_helper.blog_details_html"
                  options={{ author, published_at: i18n.l('date.formats.default2', blog.published_at) }}
                />
              </div>
              <div className="text-lg">
                <TrustedHtml
                  as="div"
                  className="trix-content"
                  sanitizationPreset={SanitizationPresets.RichTextWithMedia}
                >
                  {blog.body}
                </TrustedHtml>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import React, { useState } from 'react'
import { ApiQuery, Controls, PlainText, TimeAgo, findApiV4Link, themeColor, useJsonApi } from 'shared'
import { Donation, DonationListProps, DonationsApiResult } from './types'
import { formatCents } from '@betterplace/utils'
export const DonationList = ({
  fundraisingEvent,
  headline,
  filter = {},
  perPage = 6,
}: DonationListProps): JSX.Element | null => {
  const [page, setPage] = useState(1)
  const apiUrl = findApiV4Link({ ofObject: fundraisingEvent, withRel: 'opinions' }) ?? ''
  const query = new ApiQuery(apiUrl)
  const currentPageUrl = query.per(perPage).page(page).filter(filter).toUrl()
  const { data } = useJsonApi<DonationsApiResult>(currentPageUrl)
  if (!data) return null

  const { data: donations, total_entries: count, total_pages: totalPages } = data

  const donationRows = inGroupsOf(donations, 2).map((group, i) => {
    const donations = group.map((el, j) => <DonationCmp key={`donation-${j}`} {...el} />)
    return (
      <div key={`donation-group-${i}`} className="row">
        {donations}
      </div>
    )
  })

  const fullHeadline = headline
    ? `${headline} (${count})`
    : i18n.t('fundraising_events.opinions.index.headline', { count })
  const newDonationUrl = findApiV4Link({ ofObject: fundraisingEvent, withRel: 'new_donation' }) ?? ''

  return (
    <div className="fundraising-events-show-opinions">
      <div className="container">
        <div className="fundraising-events-opinions-index">
          <div className="row">
            <div className="col-md-24">
              <div className="headline-container">
                <h2 className="text-3xl mt-0 mb-8">{fullHeadline}</h2>

                <Controls
                  total={totalPages}
                  current={page}
                  loadNextPage={() => {
                    setPage(page + 1)
                  }}
                  loadPreviousPage={() => {
                    setPage(page - 1)
                  }}
                />
              </div>

              {count === 0 && <ZeroResult url={newDonationUrl} />}

              {donationRows}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const inGroupsOf = <T,>(arr: Array<T>, length: number): Array<Array<T>> => {
  return [...Array(Math.ceil(arr.length / length)).keys()].map((i) => {
    const offset = i * length
    return arr.slice(offset, offset + length)
  })
}

const ZeroResult = ({ url }: { url: string }): JSX.Element => (
  <div className="text-center">
    <p>
      <strong>{i18n.t('fundraising_events.opinions.index.no_donations_notice')}</strong>
    </p>
    <a href={url}>{i18n.t('fundraising_events.opinions.index.call_for_first_donation')}</a>
  </div>
)

const DonationCmp = (props: Donation) => {
  const cents = props.donated_amount_in_cents
  const donor = props.author ? (props.author.name ?? '') : i18n.t('fundraising_events.opinions.opinion.anonymous')
  const amountIsSmall = !cents || cents >= 100000 || cents % 100 > 0

  const image =
    findApiV4Link({ ofObject: (props.author || {}).picture || {}, withRel: 'fill_100x100' }) || fallbackImage

  return (
    <div className="col-md-12">
      <div className="generic-opinion media">
        <div className="opinion-avatar-and-amount media-left">
          <img className="user-avatar rounded-full" src={image} width="50" alt="" />
          {cents && (
            <div className={`amount ${amountIsSmall ? 'is-small' : ''}`}>{formatCents(i18n.locale, cents)}</div>
          )}
        </div>
        <div className="opinion-body media-body">
          <div className="headline">
            <PlainText as="strong">{donor}</PlainText>{' '}
            <span>{i18n.t('fundraising_events.opinions.index.donated_label')}</span>
            <br />
            <small>
              <TimeAgo datetime={props.confirmed_at} locale={i18n.locale} />
            </small>
          </div>
          {props.message && (
            <PlainText className="message" as="div" style={{ borderTop: `1px solid ${themeColor('gray-200')}` }}>
              {props.message}
            </PlainText>
          )}
        </div>
      </div>
    </div>
  )
}

// TODO Convert Hardcoded image, FeelsBadMan
const fallbackImage =
  'https://betterplace-assets.betterplace.org/assets/default/donation_profile_picture/fill_100x100_default-2f969919aad3a0d51372c1474e73e452ea00947cb78d944c81aa1891b590b31e.png'

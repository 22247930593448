import AddProjectsIcon from '@betterplace/assets/images/icons/add_projects.svg'
import AlertsAndOverlaysIcon from '@betterplace/assets/images/icons/alerts_and_overlays.svg'
import AllSettingsIcon from '@betterplace/assets/images/icons/all_settings.svg'
import EditFundraisingEventIcon from '@betterplace/assets/images/icons/edit_fundraising_event.svg'
import EditTitleImageIcon from '@betterplace/assets/images/icons/edit_title_image.svg'
import ForwardDonationsIcon from '@betterplace/assets/images/icons/forward_donations.svg'
import React from 'react'
import SayThankYouIcon from '@betterplace/assets/images/icons/say_thank_you.svg'
import ShareFundraisingEventIcon from '@betterplace/assets/images/icons/share_fundraising_event.svg'
import WriteNewsIcon from '@betterplace/assets/images/icons/write_news.svg'
import classNames from 'classnames'
import styles from './manage_content.module.css'
import { AdminBox, SharingGridButton, TrustedHtml, findApiV4Link } from 'shared'
import { ManageContentProps } from './types'

const iconDictionary: { [key: string]: string } = {
  add_projects: AddProjectsIcon,
  alerts_and_overlays: AlertsAndOverlaysIcon,
  all_settings: AllSettingsIcon,
  edit_fundraising_event: EditFundraisingEventIcon,
  edit_title_image: EditTitleImageIcon,
  forward_donations: ForwardDonationsIcon,
  say_thank_you: SayThankYouIcon,
  share_fundraising_event: ShareFundraisingEventIcon,
  write_news: WriteNewsIcon,
}

function ManageButton({ type, url }: { type: string; url: string }) {
  return (
    <a className={classNames(styles.iconButton, 'btn btn-large btn-outline-green text-center')} href={url}>
      <>
        <img className={styles.icon} src={iconDictionary[type]} alt="" />
        <TrustedHtml>{i18n.t(`fundraising_events.admin_boxes.labels.${type}_html`)}</TrustedHtml>
      </>
    </a>
  )
}

export const ManageContent = ({
  showManageContent,
  manageBlogsUrl,
  manageDetailsUrl,
  manageForwardingUrl,
  manageLiveWidgetsUrl,
  managePictureUrl,
  manageProjectsUrl,
  manageTransactionProtocolUrl,
  fundraisingEvent,
  category,
}: ManageContentProps): JSX.Element | null => {
  let shareUrl = findApiV4Link({ ofObject: fundraisingEvent, withRel: 'platform' }) ?? ''
  shareUrl += '?utm_campaign=creator_share&utm_medium=admin_box&utm_source=Link'
  const hasDonations = fundraisingEvent.donated_amount_in_cents > 0

  if (showManageContent === 'false') return null
  return (
    <div className="container">
      <div className="row preserves-space-for-transparent-header pb-4">
        <div className="col mt-4">
          <AdminBox variant="dark" customTitle={i18n.t('fundraising_events.admin_boxes.header_title')} quickAccessBox>
            <div className="container">
              <div className={classNames(styles.buttonContainer, 'flex flex-wrap items-center justify-center')}>
                <ManageButton type="edit_fundraising_event" url={manageDetailsUrl} />
                {hasDonations ? (
                  <ManageButton type="write_news" url={manageBlogsUrl} />
                ) : (
                  <ManageButton type="add_projects" url={manageProjectsUrl} />
                )}
                {category === 'live_streams' && <ManageButton type="alerts_and_overlays" url={manageLiveWidgetsUrl} />}
                {hasDonations ? (
                  <>
                    <ManageButton type="forward_donations" url={manageForwardingUrl} />
                    <ManageButton type="say_thank_you" url={manageTransactionProtocolUrl} />
                  </>
                ) : (
                  <ManageButton type="edit_title_image" url={managePictureUrl} />
                )}
                {category !== 'live_streams' && !hasDonations && (
                  <ManageButton type="write_news" url={manageBlogsUrl} />
                )}
                {!(category === 'live_streams' && hasDonations) && (
                  <SharingGridButton
                    className={styles.iconButton}
                    shareUrl={shareUrl}
                    platforms={['facebook', 'twitter', 'link', 'whatsapp', 'messenger', 'instagram']}
                    modalHeader={i18n.t('fundraising_events.show.basic_information.share_modal_headline')}
                    buttonLabel={
                      <>
                        <img className={styles.icon} src={iconDictionary['share_fundraising_event']} alt="" />
                        <TrustedHtml className={styles.labelLines}>
                          {i18n.t('fundraising_events.admin_boxes.labels.share_fundraising_event_html')}
                        </TrustedHtml>
                      </>
                    }
                    size="large"
                    utmMedium="fepp_admin_box"
                  />
                )}
                <ManageButton type="all_settings" url={manageDetailsUrl} />
              </div>
            </div>
          </AdminBox>
        </div>
      </div>
    </div>
  )
}

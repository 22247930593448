import React from 'react'
import classNames from 'classnames'
import { LeftSideProps, NumbersProps, ProgressProps, RightSideProps } from './types'
import { formatCents } from '@betterplace/utils'

export const Numbers = ({
  fundraisingEvent,
  donatedAmountCaption,
  sponsoringAmountCents,
  sponsoringAmountCaption,
}: NumbersProps): JSX.Element => {
  const hasTarget = !!fundraisingEvent.requested_amount_in_cents

  return (
    <div className="fundraising-events-show-numbers container pb-4">
      <div className="row text-center">
        <div className={classNames('offset-md-5 col-md-14', { 'with-target': hasTarget })}>
          <LeftSide cents={fundraisingEvent.donated_amount_in_cents} sponsoringLabel={donatedAmountCaption} />
          <RightSide
            fundraisingEvent={fundraisingEvent}
            sponsoringAmountCents={sponsoringAmountCents}
            sponsoringAmountCaption={sponsoringAmountCaption}
          />
          <Progress percentage={fundraisingEvent.progress_percentage ?? 0} hasTarget={hasTarget} />
        </div>
      </div>
    </div>
  )
}

const LeftSide = ({ cents, sponsoringLabel }: LeftSideProps): JSX.Element => {
  return (
    <li className="left-side">
      <div className="number">{formatCents(i18n.locale, cents)}</div>
      <div className="legend">{sponsoringLabel || i18n.t('fundraising_events.show.numbers.donated_amount_label')}</div>
    </li>
  )
}

const RightSide = ({
  fundraisingEvent,
  sponsoringAmountCents,
  sponsoringAmountCaption,
}: RightSideProps): JSX.Element => {
  const requestedCents = fundraisingEvent.requested_amount_in_cents ?? 0
  const donationsCount = fundraisingEvent.donations_count
  /** @type {string | number} */
  let number: string | number
  /** @type {string} */
  let legend: string

  if (parseInt(`${sponsoringAmountCents ?? 0}`, 10) > 0) {
    number = formatCents(i18n.locale, +`${sponsoringAmountCents ?? 0}`)
    legend = sponsoringAmountCaption ?? ''
  } else if (requestedCents > 0) {
    number = formatCents(i18n.locale, requestedCents)
    legend = i18n.t('fundraising_events.show.numbers.target_amount_label')
  } else {
    number = donationsCount
    legend = i18n.t('fundraising_events.show.numbers.donation_count_label', {
      count: donationsCount,
    })
  }
  return (
    <li className="right-side">
      <div className="number">{number}</div>
      <div className="legend">{legend}</div>
    </li>
  )
}

const Progress = ({ percentage, hasTarget }: ProgressProps): JSX.Element | null => {
  if (!hasTarget) return null
  return (
    <li className="progress-bar-container">
      <div className="progress generic-progress-bar mb-3">
        <div className="bar" style={{ width: `${percentage}%` }}></div>
      </div>
    </li>
  )
}
